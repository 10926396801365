// @media (min-width: 768px) {
// }
// @media (min-width: 1280px) {
// }
// @media (min-width: 1536px) {
// }

.header {
  background-color: transparent;
  // min-height: 50px;
  // max-height: 50px;
  box-shadow: 0px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  padding: 0;
  transition: background-color 0.5s ease;
  @media screen and (min-width: 1280px) {
    padding: 15px 0px;
  }
  .nav {
    a {
      text-transform: capitalize;
      padding: 1rem;
      font-weight: 600;
      font-size: 16px;
      
    }
    a:hover{
      color: aqua;
    }
  }
}

.root-content {
  height: 100vh;
  overflow: hidden;
  background-color: white;
  .root-main {
    overflow: auto;
    height: 100vh;
  }
}

.main {
  padding-top: 70px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  .main-content {
    .container-without-padding {
      margin-top: -70px;
    }
  }
}

.drawer-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 250px;
  transition: 0.4s ease-in;
  transform: translate(100%);
  z-index: 1;
  background-color: white;
  padding: 1rem;
}

@media screen and (max-width: 400px) {
  .drawer-container {
    width: 100%;
  }
}

.show-drawer {
  transform: translate(0);
}

.footer {
  position: relative;
  width: 100%;
  bottom: 0;
  z-index: 0;
  .bg-footer {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  a {
    text-decoration: none;
  }
  .container-footer {
    display: flex;
    align-items: center;
    padding: 40px 0px 20px 0px;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 1280px) {
      flex-direction: row;
      gap: 10px;
    }
    .navigation-footer {
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      gap: 14px;
      flex-direction: column;
      @media (min-width: 768px) {
        flex-direction: row;
        gap: 22px;
        width: fit-content;
      }
    }
    .navigation-footer a:nth-child(n + 2) {
      border-left: 0px;
      padding-left: none;
      border-top: 1px solid white;
      padding-top: 14px;
      width: 100%;
      @media (min-width: 768px) {
        padding-top: 0px;
        border-top: 0px;
        border-left: 2px solid white;
        flex-direction: row;
        padding-left: 22px;
      }
    }

    .navigation-footer2 {
      display: flex;
      align-items: center;
      text-align: center;
      gap: 22px;
      width: 100%;
      flex-direction: column;
      @media (min-width: 768px) {
        display: none;
        width: fit-content;
      }
      a {
        border-top: 1px solid white;
        padding-top: 22px;
        width: 100%;
      }
    }

    .footer-socmed {
      display: none;
      gap: 8px;
      flex-wrap: wrap;
      @media (min-width: 1280px) {
        display: flex;
      }
    }

    .footer-socmed2 {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      padding: 10px 0px;
      @media (min-width: 768px) {
        padding: 0px;
      }
      @media (min-width: 1280px) {
        display: none;
      }
    }
  }
  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0px;
    padding: 8px 0 8px 0px;

    p {
      width: 100%;
      text-align: center;
      padding-top: 20px;
    }

    @media (min-width: 768px) {
      border-top: 1px solid white;
      p {
        width: fit-content;
        padding-top: 0px;
      }
    }

    .footer-left-side {
      display: none;
      align-items: center;
      gap: 8px;
      @media (min-width: 768px) {
        display: flex;
      }
    }
  }
}
