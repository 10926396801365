
@import '@pmberjaya/kitakerja-ui/build/styles.css';

@import "custom.scss";
@import "premium.scss";

html {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow: auto;
}

#__next {
  background-color: #F0F2F5;
}

a {
  color: #0d6efd;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.hide-input-number-arrow {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none!important;
    margin: 0!important;
  }
  -moz-appearance: textfield!important;
}

.whatsapp {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem .5rem;
  z-index: 1;
  width: 125px;
  .scaffold {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .wa-btn {
      position: absolute;
      top: -60px;
      right: 0px;
    }
  }
  &:before {
    content: url('/images/waving.svg');
    display: inline-block;
    width: 125px;
    position: relative;
    bottom: -50px;
    right: 15px;
  }
}

.text-justify {
  text-align: justify;
}

.text-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis-2lines {
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}