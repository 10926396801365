.premium-list{
    ul{
        list-style: none;
        margin-left: 10px;
        padding: 0;
        li{
            margin-bottom: 3px;
            display: flex;
            align-items: center;
        }
        li::before {
            color: transparent;
            font-size: 1px;
            content: '';
            margin-left: 1.3em;
            margin-right: 12px;
            padding: 7px;
            border-radius: 100%;
            background-color: #356FFD;
            mask: cover;
        }
    }
}

.cursor-pointer{
    cursor: pointer;
}