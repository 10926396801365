.premium-badge {
    position: relative;
  
    .premium-icon {
      position: absolute;
      bottom: -4px;
      right: 4px;
      color: white;
      width: 18px;
      height: 18px;
      z-index: 1;
      background-color: #F68920;
      padding: .2rem;
      border-radius: 50%;
    }
  
    .premium-user:before {
      content: "";
      position: absolute;
      height: 150px;
      width: 20px;
      background : rgb(255, 255, 255);
      left: -10px;
      top: -80px;
      transform: rotate(37deg);
      transition: all .3s;
      opacity: 0.3;
      box-shadow: 0px 0px 10px white;
      animation: premium-user-animation 5s infinite;
    }
  
  
  
    .premium-icon-big {
      padding: .4rem;
      width: 30px;
      height: 30px;
      bottom: -3px;
      right: 5px;
    }
  
    .premium-user {
      border: 1.5px solid #F68920;
    }
  
    .premium-user-big {
      border: 2px solid #F68920;
    }
  
    .premium-user-big:before {
      height: 250px;
      width: 40px;
      left: -10px;
      top: -60px;
      animation: premium-user-big-animation 5s infinite;
    }
  
    @keyframes premium-user-animation {
      0% {
        left: -50%;
      }
      100% {
        left: 150%;
      }
    }
    
    @keyframes premium-user-big-animation {
      0% {
        left: -50%;
      }
      100% {
        left: 120%;
      }
    }
  }